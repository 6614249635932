import { KeycloakOnLoad } from 'keycloak-js';

export const environment = {
  production: false,
  api: 'https://test.mijnsportvisserij.nl/svnl/api',
  keycloak: {
    url: 'https://test.mijnsportvisserij.nl/auth',
    realm: 'Svnl',
    clientId: 'funnel',
    initOptions: {
      onLoad: 'check-sso' as KeycloakOnLoad,
      silentCheckSsoRedirectUri: '',
      redirectUri: '',
    },
  },
  endOfYearWarning: {
    enabled: true,
    currentYear: 2024,
    nextYear: 2025,
    validYear: 2024,
  },
  buckaroo: {
    applepay: {
      displayName: 'Sportvisserij Nederland (Test)',
      domainName: 'test.vispas.nl',
      merchantIdentifier: '4C26589D1AAC47B0A409344CBEF394C2',
      label: 'Sportvisserij Nederland Webshop',
    },
  },
  portal: {
    login: 'https://test.mijnsportvisserij.nl/',
    permits: 'https://test.mijnsportvisserij.nl/permits',
    account: 'https://test.mijnsportvisserij.nl/profile',
    coupling: 'https://test.mijnsportvisserij.nl/coupling/cardnumber',
  },
  analytics: {
    google: null,
    hotjar: null,
    adftrack: null,
    gtm: null,
  },
  maintenance: false,
  showDebugFooter: false,
};
