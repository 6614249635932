import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Organisation } from '@model/organisation';
import { Permit } from '@model/permit';
import { Profile } from '@model/profile';
import { KeycloakService } from 'keycloak-angular';
import * as _ from 'lodash';
import { isUndefined } from 'lodash';
import moment from 'moment';
import { map, Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';

import { BaseService } from './base.service';
import { HsvService } from './hsv.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends BaseService {
  profile: Profile;

  constructor(
    httpClient: HttpClient,
    private readonly keycloakService: KeycloakService,
    private readonly sessionService: SessionService,
    private readonly hsvService: HsvService
  ) {
    super(httpClient, 'funnel/profile');
  }

  getProfile(): Profile {
    if (_.isEmpty(this.profile)) {
      const sessionProfile = this.sessionService.getProfile();
      if (sessionProfile != null && !_.isEmpty(sessionProfile)) {
        this.profile = sessionProfile;
      }
    }

    return this.profile;
  }

  setProfile(profile: Profile): void {
    this.profile = { ...profile };
    this.sessionService.setProfile(this.profile);
  }

  getRemoteProfile(): Observable<Profile> {
    const profile = this.getProfile();

    if (
      profile == null ||
      profile.hsvId == null ||
      profile.idmPersonUUID == null
    ) {
      return this.httpClient.get<Profile>(`${this.endpointUrl}`).pipe(
        map((remoteProfile) => {
          this.setProfile(remoteProfile);

          return remoteProfile;
        })
      );
    } else {
      return of(profile);
    }
  }

  clearProfile(): void {
    this.profile = null;
    this.sessionService.setProfile(this.profile);
  }

  getMembershipForOrganisation(organisation: Organisation): Permit {
    this.profile = this.getProfile();
    if (this.profile && this.profile.permits) {
      return this.profile.permits.find(
        (permit) =>
          permit.organisation &&
          permit.organisation.hsvId === organisation.hsvId
      );
    }

    return null;
  }

  getAge(): number | boolean {
    this.profile = this.getProfile();
    if (this.profile.dateOfBirth) {
      const dobMoment = moment(this.profile.dateOfBirth, 'YYYY-MM-DD');
      if (dobMoment) {
        return moment().diff(dobMoment, 'years');
      }
    }

    return false;
  }

  //TODO kan dit zonder kuren? undefined ipv boolean
  getAgeJanuaryFirst(): number | undefined {
    this.profile = this.getProfile();
    if (!isUndefined(this.profile) && !isUndefined(this.profile.dateOfBirth)) {
      const dobMoment = moment(this.profile.dateOfBirth, 'YYYY-MM-DD');
      if (dobMoment) {
        let janFirstMoment;

        if (environment.endOfYearWarning.currentYear) {
          janFirstMoment = moment()
            .date(1)
            .month(0)
            .year(environment.endOfYearWarning.currentYear)
            .hour(0)
            .minute(0)
            .second(0);
        } else {
          janFirstMoment = moment()
            .date(1)
            .month(0)
            .hour(0)
            .minute(0)
            .second(0);
        }

        return janFirstMoment.diff(dobMoment, 'years');
      }
    }

    return undefined;
  }

  getYouthAgeLimit(): number {
    return 13;
  }

  //if there is a age available, check if the user is a youth.
  isYouth(): boolean {
    const ageJanuaryFirst = this.getAgeJanuaryFirst();
    return ageJanuaryFirst === undefined || ageJanuaryFirst < this.getYouthAgeLimit();
  }

  setMembershipNumber(membershipNumber: string): void {
    this.getProfile().membershipNumber = membershipNumber;
    this.setProfile(this.profile);
  }

  getHsvId(): number {
    return this.getProfile()?.hsvId;
  }

  getUuid(): string {
    return this.getProfile()?.idmPersonUUID;
  }

  /**
   * Check if a user has a permit / main membership
   * @returns {*}
   */
  hasMainMembership(): boolean {
    return (
      this.hasMembership(this.hsvService.getPermitId()) ||
      this.hasMembership(this.hsvService.getYouthPermitId())
    );
  }

  /**
   * Check if a user already has a membership for the given HsvDoducmentId
   *
   * @param documentId The HSV document id
   */
  hasMembership(documentId: number): boolean {
    this.profile = this.getProfile();

    if (this.profile && this.profile.permits) {
      return (
        this.profile.permits.filter(
          (document) =>
            document.documentId === documentId && document.paid === true
        ).length > 0
      );
    }

    return false;
  }

  /**
   * Check if a user already has had a membership for the given HsvDoducmentId, which
   * doesnt require the payed status to be true
   *
   * @param documentId The HSV document id
   */
  hadMembership(documentId: number): boolean {
    let returnValue = false;
    this.profile = this.getProfile();

    if (this.profile && this.profile.permits) {
      this.profile.permits.find(
        (document) => (returnValue = document.documentId === documentId)
      );
    }

    return returnValue;
  }

  /**
   * Check if a user has had a main membership in the past. Only used when a user wants to order additions.
   *
   * @param documentId The HSV document id
   */
  hadMainMembership(): boolean {
    return (
      this.hadMembership(this.hsvService.getPermitId()) ||
      this.hadMembership(this.hsvService.getYouthPermitId())
    );
  }

  /** Find a user by his keycloak ID */
  validateProfile(profile: Profile): Observable<Profile> {
    console.debug('Validate profile for: ', profile);

    return this.httpClient.post<Profile>(
      `${this.endpointUrl}/validate`,
      profile
    );
  }

  /** Find a user by his keycloak ID */
  submitProfile(profile: Profile): Observable<Profile> {
    console.debug('Submitting profile for: ', this.profile.idmPersonUUID);

    return this.httpClient.post<Profile>(`${this.endpointUrl}/submit`, profile);
  }

  register(profile: Profile): Observable<Profile> {
    return this.httpClient.post<Profile>(
      `${this.endpointUrl}/register`,
      profile
    );
  }

  getMainMembershipOrganisation(): Organisation {
    if (this.hasMainMembership()) {
      const found = this.profile.permits.find(
        (permit) =>
          permit.documentId === this.hsvService.getPermitId() ||
          permit.documentId === this.hsvService.getYouthPermitId()
      );

      if (found) {
        return found.organisation;
      }
    }

    return null;
  }

  getExpiredMainMembershipOrganisation(): Organisation {
    if (this.hadMainMembership()) {
      const found = this.profile.permits.find(
        (permit) =>
          permit.documentId === this.hsvService.getPermitId() ||
          permit.documentId === this.hsvService.getYouthPermitId()
      );

      if (found) {
        return found.organisation;
      }
    }

    return null;
  }

  hasMembershipWithOrganisation(hsvId: number): Permit {
    const profile = this.getProfile();
    if (profile && profile.permits) {
      const found = profile.permits.find(
        (permit) => permit.organisation && permit.organisation.hsvId === hsvId
      );
      if (found) {
        return found;
      }
    }

    return null;
  }
}
